<template>
    <div ref='parent' 
        :style="{
            'min-height': height  + 'px', 'max-height': height  + 'px',
        }"
        style="width:100%;padding:4px 8px;overflow-x: auto;overflow-y:hidden;">
        <div v-if="title && title.length"
            style="width:100%;text-align:center;height:34px;color:#2E7D32;font-size:20px;text-align:center;line-height:34px;font-weight:600;background-color:#A5D6A7;">
            {{title}}
        </div>

        <div style="height:100%;width:100%;margin-top:4px;overflow-x:auto;"
            :style="{
               'min-width': $vuetify.breakpoint.xsOnly?'240vw':chartMinWidth,
               'min-height': height + 'px', 'max-height': height  + 'px',
            }"
            v-if="showChart"
            >
            <vue-chart
                style="width:100%;"
                :style="{
                    'min-width': $vuetify.breakpoint.xsOnly?'240vw':chartMinWidth,
                    'min-height': (height-32)+'px', 'max-height': (height-32)+'px',
                }"
                :type="chartType?chartType:'line'" 
                :data="chartData" 
                :options="chartOptions"></vue-chart>
        </div>
    </div>
</template>
<script>
let moment = require('moment')
moment.locale("vi")
export default {
    props: ['title', 'loadIndex', 'points', 'labels', 'scale', 'height', 'chartType', 'dontBeginAtZero'],
    watch: {
        scale: function () {
           this.updateScale()
        },
        points: function() {        
            this.processBlock()
        },
        loadIndex: function(){
            let self = this
            this.showChart = false
            setTimeout(()=>{
                self.processBlock()
                self.showChart = true
            },100)
        }
    },
    data () {
        return {
            showChart: true,
            chartMinWidth: '100px',
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: "",
                        data: [],
                        backgroundColor: '#86B8FD'
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                animation: { duration: 0 },
                scales: {
                    yAxes: [{
                        ticks: { beginAtZero: !this.dontBeginAtZero }
                    }]
                },
                legend: { display: false },
                tooltips: { enabled: true },
                plugins: {
                    datalabels: {
                        display: false
                    }
                }
            },
            items: []
        }
    },
    methods: {
      updateScale (){
         let self = this
         if(this.scale){
            let newWith = 0
            if (this.scale==1) {
               newWith = this.scale*this.$refs.parent.clientWidth-30
            } else {
               newWith = this.scale*this.$refs.parent.clientWidth
            }
            newWith = Math.min(9200, newWith)
            this.chartMinWidth = newWith + 'px'
            
            this.showChart = false
            setTimeout(()=>{
                self.showChart = true
                self.processBlock()
            },100)
         }
      },
      clearChartData: function () {
         this.chartData.labels = []
         this.chartData.datasets = [{
               label: [],
               data: [],
               backgroundColor: '#86B8FD'
         }]
      },
      processBlock: function (){
        //  console.log("chart processBlock")
         
         this.clearChartData()
         if(this.points && this.points.length>0){
            this.chartData.labels = this.labels
            this.chartData.datasets = []
            this.points.forEach(point => {
                // console.log('point.active ' + point.active)
                if(point.active){
                    this.chartData.datasets.push({
                        label: point.displayName,
                        data: point.dataItems,
                        backgroundColor: point.backgroundColor?point.backgroundColor:"#00000000",
                        borderColor: point.color || '#111111',
                        fill: true,
                        borderWidth: 2,
                        lineTension: 0.4,
                        pointRadius: 0,
                    })
                }
               
            })
         }
      },
   },
   mounted: function () {
      this.$nextTick(() => {
         this.updateScale()
      })
   }
}
</script>
